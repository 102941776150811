import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, MemoryRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
//import history from './history'
import './locales'

function App() {

    /*
    const [projectList,setProjectList] = React.useState([{}])
    React.useEffect(()=>{
      fetch('data/projects.json').then((res)=>res.json()).then((data)=>{
        setProjectList(data)
       })
       
    },[])
    */

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                {/*<BrowserRouter history={history}>*/}
                <MemoryRouter>
                    <Theme>
                        <Layout />
                    </Theme>
                </MemoryRouter>
                {/*</BrowserRouter>*/}
            </PersistGate>
        </Provider>
    )
}

export default App
