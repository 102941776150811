/**
 * Since some configurations need to be match with specific themes,
 * we recommend to use the configuration that generated from demo.
 */

export const themeConfig = {
    themeColor: 'my-color',
    primaryColorLevel: 100,
    direction: 'ltr',
    mode: 'dark',
    cardBordered: true,
    panelExpand: false,
    controlSize: 'md',
    navMode: 'dark',
    layout: {
        type: 'modern',
        sideNavCollapse: false,
    },
}
